import React from 'react';

import { useImmer } from 'use-immer';

type RoutesContextTypeContent = {
  routes?: any;
  token?: string;
};
type RoutesContextType = {
  routesCxt?: RoutesContextTypeContent;
  updateRoutesCtx?: any;
};

export const RoutesContext = React.createContext<RoutesContextType>({});

export const RoutesContextProvider = (props: { children: any }) => {
  const [routesCxt, updateRoutesCtx] = useImmer<RoutesContextTypeContent>({});

  return <RoutesContext.Provider value={{ routesCxt, updateRoutesCtx }}>{props?.children}</RoutesContext.Provider>;
};
