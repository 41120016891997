import React from 'react';

import styled from 'styled-components';

import { RegisterEmailForm } from './emailForm';
import { EmailImage } from './emailImage';
import { ImageOnSide } from './imageOnSide';

const Style = styled.div`
  background: #f8f8f8;
  max-width: 1200px;
  margin: auto;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
`;

export function RegisterEmailPage(props: { email?: string; code?: string; image?: JSX.Element }) {
  return (
    <Style>
      <ImageOnSide image={props.image || <EmailImage />}>
        <RegisterEmailForm email={props.email} code={props.code} />
      </ImageOnSide>
    </Style>
  );
}

export default RegisterEmailPage;
