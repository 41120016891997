import React, { useContext } from 'react';

import { notification } from 'antd';

import { AxiosContext, AxiosContextType } from '../context/AxiosContext';
import { LocaleHelp, LocaleTitle } from '../fstn/locale/index';
import { UserContextType } from '../interfaces/index';

export function useUser(userContext: UserContextType) {
  const { updateToken } = useContext<AxiosContextType>(AxiosContext);
  const { axios } = useContext<AxiosContextType>(AxiosContext);

  const loadMe = async (token?: string) => {
    try {
      let config = {};

      if (token) {
        config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      }

      const res = await axios?.get(`/me`, config);

      if (res?.data) {
        await userContext.updateUserCtx((draft: any) => {
          draft.user = res.data;
        });
      }
    } catch (error) {
      console.log(error);
      userContext.updateUserCtx((draft: any) => {
        draft.token = undefined;
      });

      if (window.location.pathname !== '/login-form-page') {
        // navigate("/login-form-page")s
        return Promise.reject(error);
      }
    }
  };
  const login = async (email: string, password: string, remember: string) => {
    try {
      localStorage.removeItem('SecretaryContext');
      const res = await axios?.post(
        `/login`,
        {
          username: email || 'candidate@yopmail.com',
          password: password || 'test',
          remember,
        },
        {},
      );

      if (res && res.status < 300) {
        const accessToken = res?.data.access_token;

        await updateToken?.(accessToken);
        await loadMe(accessToken);

        return res?.data.payload;
      }
    } catch (e: any) {
      if (e?.response?.status === 401) {
        await updateToken?.(undefined);
        notification.error({
          message: <LocaleTitle tkey="login-form-page.invalid" />,
          description: <LocaleHelp tkey="login-form-page.invalid" />,
        });
      } else {
        notification.warn({
          message: <LocaleTitle tkey="server.error" />,
          description: <LocaleHelp tkey="server.error" />,
        });
      }

      throw e;
    }
  };

  return {
    loadMe,
    login,
  };
}
